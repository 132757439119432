import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const Title = () => <span>
    Huhu
  </span>;
export const _frontmatter = {};
const layoutProps = {
  Title,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`MDX content starts here`}</h2>
    <p>{`jojo`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      